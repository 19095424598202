<template>
  <div>
    <heads></heads>
    <div class="cen">
      <div class="cen_box">
        <div class="cen_box_container">
          <router-link
            :to="{
              path: '/theory',
            }"
            class="cen_box_item"
          >
            理论研究中心
          </router-link>
          <router-link
            :to="{
              path: '/video/microCourse',
            }"
            class="cen_box_item"
          >
            微课程教学区
          </router-link>

          <router-link
            :to="{
              path: '/video/videoTrainingDirectory',
            }"
            class="cen_box_item"
          >
            视频培训专区
          </router-link>

          <router-link
            :to="{
              path: '/video/enTrain',
            }"
            class="cen_box_item"
          >
            师资培训专区
          </router-link>

          <router-link
            :to="{
              path: '/video/modelCourse',
            }"
            class="cen_box_item"
          >
            优秀示范课
          </router-link>
          <router-link
            :to="{
              path: '/preparation',
            }"
            class="cen_box_item"
          >
            备课资源中心
          </router-link>

          <router-link
            :to="{
              path: '/practice',
            }"
            class="cen_box_item"
          >
            综合实践课程设计
          </router-link>

          <router-link
            :to="{
              path: '/keti/monishiti',
            }"
            class="cen_box_item"
          >
            传统文化中高考模拟测试
          </router-link>

          <router-link
            :to="{
              path: '/xuekejiaoyan',
            }"
            class="cen_box_item"
          >
            学科教研专区
          </router-link>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import "@css/ico.css";
import "@css/foot.css";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
export default {
  components: {
    heads,
    foot,
  },
};
</script>
<style scoped>
.cen {
  width: 100%;
  min-width: 1484px;
  height: 1195px;
  background: url(../assets/images/ke.jpg);
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
.cen_box {
  width: 1484px;
  height: 1195px;
  margin: 0 auto;
  position: relative;
}

.cen_box_container {
  position: absolute;
  left: 162px;
  top: 360px;
  width: 1030px;
}

.cen_box_item {
  position: relative;
  float: left;
  text-decoration: none;
  color: inherit;
  font-weight: 900;
  width: 30px;
  display: inline-flex;
  font-size: 34px;
  font-family: "楷体";
  writing-mode: vertical-lr;
}

.cen_box_item + .cen_box_item {
  margin-left: 94px;
}
</style>
