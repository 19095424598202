var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('heads'),_c('div',{staticClass:"cen"},[_c('div',{staticClass:"cen_box"},[_c('div',{staticClass:"cen_box_container"},[_c('router-link',{staticClass:"cen_box_item",attrs:{"to":{
            path: '/theory',
          }}},[_vm._v(" 理论研究中心 ")]),_c('router-link',{staticClass:"cen_box_item",attrs:{"to":{
            path: '/video/microCourse',
          }}},[_vm._v(" 微课程教学区 ")]),_c('router-link',{staticClass:"cen_box_item",attrs:{"to":{
            path: '/video/videoTrainingDirectory',
          }}},[_vm._v(" 视频培训专区 ")]),_c('router-link',{staticClass:"cen_box_item",attrs:{"to":{
            path: '/video/enTrain',
          }}},[_vm._v(" 师资培训专区 ")]),_c('router-link',{staticClass:"cen_box_item",attrs:{"to":{
            path: '/video/modelCourse',
          }}},[_vm._v(" 优秀示范课 ")]),_c('router-link',{staticClass:"cen_box_item",attrs:{"to":{
            path: '/preparation',
          }}},[_vm._v(" 备课资源中心 ")]),_c('router-link',{staticClass:"cen_box_item",attrs:{"to":{
            path: '/practice',
          }}},[_vm._v(" 综合实践课程设计 ")]),_c('router-link',{staticClass:"cen_box_item",attrs:{"to":{
            path: '/keti/monishiti',
          }}},[_vm._v(" 传统文化中高考模拟测试 ")]),_c('router-link',{staticClass:"cen_box_item",attrs:{"to":{
            path: '/xuekejiaoyan',
          }}},[_vm._v(" 学科教研专区 ")])],1)])]),_c('foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }